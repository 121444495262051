<section class="create payment">
  <div class="create_title">{{title}}</div>
  <div cdkFocusInitial class="close-dialog" mat-dialog-close>
    <svg height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
      <path d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
        data-name="Path 7160" fill="#0c2e56" id="Path_7160" transform="translate(-5 -5)" />
    </svg>
  </div>
  <div *ngIf="dataLoading" class="dataLoading">
    <mat-spinner [color]="'warn'" class="m-auto" diameter="50"></mat-spinner>
  </div>
  <mat-dialog-content *ngIf="!dataLoading">
    <div class="payment_paymentRef">
      {{"traningDetails.Payment Reference No"|translate}}.:
      <span class="refNumber">
        {{ paymentData?.transaction_number }}
      </span>
    </div>
    <div [ngClass]="{
        payment_paymentInfoPending: paymentData?.class_name == 'badge-orange',
        payment_paymentInfoApproved: paymentData?.class_name == 'badge-green',
        payment_paymentInfoDecline: paymentData?.class_name == 'badge-red'
      }">
      <div class="row">
        <div class="col-sm-12 committeeDetails_status">
          <div [class]="paymentData?.class_name">
            {{ paymentData?.status }}
          </div>
        </div>
        <div class="col-12 row">
          <div class="col-md-6 col-12 row">
            <div class="col-sm-12 my-3 committeeDetails_info-createdBy">
              <div class="committeeDetails_info-createdBy avatar">
                <div class="avatar-container">
                  <img (error)="paymentData.creator_image = ''" *ngIf="paymentData?.creator_image"
                    [src]="paymentData?.creator_image" />
                  <div *ngIf="!paymentData?.creator_image" [innerHTML]="paymentData?.creator_name | shortName"></div>
                </div>
              </div>
              <div class="committeeDetails_info-createdBy title">{{"traningDetails.Done by"|translate}}</div>
              <div class="committeeDetails_info-createdBy content">
                {{ paymentData?.creator_name }}
              </div>
            </div>
            <div class="col-sm-12">
              <div class="committeeDetails_info-createdBy title">{{"traningDetails.Paid on"|translate}}</div>
              <div class="committeeDetails_info-createdBy content">
                {{ paymentData?.paid_on | unixDate }}
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12 row"
            *ngIf="paymentData.approved_user_image !=null&& paymentData.approved_user_name !=null && paymentData.approved_date !=null">
            <div class="col-sm-12 my-3 committeeDetails_info-createdBy">
              <div class="committeeDetails_info-createdBy avatar">
                <div class="avatar-container">
                  <img (error)="paymentData.approved_user_image = ''" *ngIf="paymentData?.approved_user_image"
                    [src]="paymentData?.approved_user_image" />
                  <div *ngIf="!paymentData?.approved_user_image"
                    [innerHTML]="paymentData?.approved_user_name | shortName"></div>
                </div>
              </div>
              <div class="committeeDetails_info-createdBy title">{{"traningDetails.Approved By"|translate}}</div>
              <div class="committeeDetails_info-createdBy content">
                {{ paymentData?.approved_user_name }}
              </div>
            </div>
            <div class="col-sm-12">
              <div class="committeeDetails_info-createdBy title">{{"traningDetails.Approved_on"|translate}}</div>
              <div class="committeeDetails_info-createdBy content">
                {{ paymentData?.approved_date | unixDate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="payment_invoice">
      <div class="row">
        <div class="col-sm-12">
          <div class="invoice-title">{{"traningDetails.Invoice Details"|translate}}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="invoice-content">
            <div class="row">

              <div class="col-sm-8">
                <div class="content-desc">
                  {{ paymentData?.course_name }}
                </div>
              </div>
              <div class="col-sm-4">
                <div class="content-price">{{ paymentData?.amount }} {{paymentData?.currency}}</div>
              </div>

              <div class="col-sm-12">
                <div class="content-title">
                  {{ paymentData?.payment_options }}
                </div>
              </div>

              <div class="col-sm-8" *ngIf="paymentData?.payment_type">
                <div class="content-desc">
                  {{"training.Payment Type"|translate}}
                </div>
              </div>
              <div class="col-sm-4" *ngIf="paymentData?.payment_type">
                <div class="content-price">
                  <span [ngSwitch]="paymentData?.payment_type">
                    <p *ngSwitchCase="1" class="m-0">
                      {{"training.individual"|translate}}
                    </p>
                    <p *ngSwitchCase="2" class="m-0">
                      {{"training.Multi"|translate}}
                    </p>
                    <p *ngSwitchDefault class="m-0">
                      -
                    </p>
                  </span>
                </div>
              </div>

              <div class="col-sm-8" *ngIf="paymentData?.number_of_participants">
                <div class="content-desc">
                  {{"training.Number of Participants"|translate}}
                </div>
              </div>
              <div class="col-sm-4" *ngIf="paymentData?.number_of_participants">
                <div class="content-price">{{ paymentData?.number_of_participants || "-"}}</div>
              </div>

              <div class="col-sm-8" *ngIf="paymentData?.account_holder_name">
                <div class="content-desc">
                  {{"training.Account Holder Name/Number"|translate}}
                </div>
              </div>
              <div class="col-sm-4" *ngIf="paymentData?.account_holder_name">
                <div class="content-price">{{ paymentData?.account_holder_name }}</div>
              </div>
              <!--
              <div class="col-sm-4" *ngIf="paymentData?.payment_type">
                <div >  </div>
              </div>
              <div class="col-sm-4" *ngIf="paymentData?.number_of_participants">
                <div > </div>
              </div>
              <div class="col-sm-4" *ngIf="paymentData?.account_holder_name">
                <div > </div>
              </div> -->
              <div class="col-sm-12" *ngIf="paymentData?.file">
                <app-files-viewer [files]="[paymentData?.file]"></app-files-viewer>
              </div>

              <div class="col-sm-12" *ngIf="paymentData?.trainee_invoice_file ">
                <app-files-viewer [files]="[paymentData?.trainee_invoice_file ]"></app-files-viewer>
              </div>

              <div class="col-sm-12">
                <div class="separator"></div>
              </div>
              <div class="col-sm-8">
                <div class="supposed-title">{{"traningDetails.Supposed Paid"|translate}}</div>
              </div>
              <div class="col-sm-4">
                <div class="supposed-amount">{{ paymentData?.amount }} {{paymentData?.currency}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="payment_hint">
      <div class="row">
        <div class="col-sm-12">
          <div class="border-slip"></div>
        </div>
        <div class="col-sm-12">
          <div class="hint-content">
            Once you check the payment transaction through HYPER PAY platform,
            Kindly take your decision by approving the payment or decline it.
          </div>
        </div>
      </div>
    </div> -->

    <div *ngIf="actionArray?.length && !data.paymentDetails" class="payment_actions">
      <div class="row">
        <div *ngFor="let btn of actionArray" class="col">
          <div (click)="onTakeAnAction(btn.api_link)" [class]="'btn-action-' + btn.color" [class.loading]="loading">
            <span *ngIf="btn.color == 'green'">
              <svg height="20" viewBox="0 0 17 20" width="17" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0,20V12.633H1.809V20Zm0-8.512v-.879H.008L0,.383A.382.382,0,0,1,.389,0H16.61a.425.425,0,0,1,.125.02.383.383,0,0,1,.244.488L15.361,6.242a.386.386,0,0,0,0,.246l1.618,4.52a.393.393,0,0,1,.016.1.383.383,0,0,1-.358.383ZM3.693,5.783a.488.488,0,0,0,0,.689l2.727,2.7a.284.284,0,0,0,.16.061.225.225,0,0,0,.149-.057L12.2,3.754a.235.235,0,0,0,0-.326L11.063,2.3a.228.228,0,0,0-.156-.064.225.225,0,0,0-.156.064L6.763,6.254a.265.265,0,0,1-.369,0l0,0-1.4-1.387a.27.27,0,0,0-.373,0h0Z"
                  fill="#fff" id="approve-registration-request-icon" opacity="0.85" />
              </svg>
            </span>
            <span *ngIf="btn.color == 'red' || btn.color == 'yellow'">
              <svg height="20.004" viewBox="0 0 17.002 20.004" width="17.002" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4371.074,17843.643v-7.371h1.8v7.371Zm0-8.512v-.883h.014l-.009-10.223a.387.387,0,0,1,.385-.387h16.225a.4.4,0,0,1,.125.023h0a.386.386,0,0,1,.237.488l-1.621,5.736a.374.374,0,0,0,0,.242l1.621,4.518a.418.418,0,0,1,.013.1.383.383,0,0,1-.358.381Zm9.821-2.621a.19.19,0,0,0,.264.006l0-.006.976-.994a.2.2,0,0,0,0-.281l-1.478-1.5-.345-.35.345-.35,1.478-1.5a.2.2,0,0,0,0-.281l-.976-.99a.183.183,0,0,0-.269,0l-1.827,1.854-1.832-1.854a.178.178,0,0,0-.139-.059.159.159,0,0,0-.13.059l-.981.99a.2.2,0,0,0,0,.281l1.482,1.5.345.35-.345.35-1.482,1.5a.2.2,0,0,0,0,.281l.981.994a.19.19,0,0,0,.264.006l0-.006,1.832-1.857Z"
                  fill="#fff" id="reject-registration-request-icon" opacity="0.85"
                  transform="translate(-4371.07 -17823.639)" />
              </svg>
            </span>
            {{ btn.button_title }}
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</section>
