<div class="container-fluid resend-container p-0 text-center">
  <div (click)="onClose()" cdkFocusInitial class="close-dialog">
    <mat-icon>close</mat-icon>
  </div>
  <div class="img-container"></div>
  <div class="title">{{'TrainingDetails.Check your inbox!' | translate}}</div>
  <div class="info px-3">
    {{'TrainingDetails.resendMailtop' | translate}}
    <br>
    {{'TrainingDetails.resendMail' | translate}}
  </div>
  <button
    (click)="onResendMail()"
    [class.loading]="loading"
    [disabled]="loading"
    class="resend-btn"
    mat-button
  >
    <img
      alt=""
      src="../../../../assets/images/resend-verification-mail-icon.svg"
    />
    <span>{{'TrainingDetails.Resend Verification Mail' | translate}}</span>
  </button>
</div>
