import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AllTrainingListService } from 'src/app/features/training/services/all-training-list.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-payment-confirmation-page',
  templateUrl: './payment-confirmation-page.component.html',
  styles: [
  ]
})
export class PaymentConfirmationPageComponent implements OnInit {

  dataLoading = false;
  paymentId = null;
  paymentToken = null;

  constructor(
    private allTrainingListService: AllTrainingListService,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.id) {
        this.paymentId = params.id;
      }
    });
    this.paymentToken = this.route.snapshot.params['token'];
    console.log(this.paymentId, this.paymentToken);
    this.getDataToPaymentGetway()
  }

  getDataToPaymentGetway() {
    this.dataLoading = true;
    const data = {
      paymentConfirmationToken: this.paymentToken,
      id: this.paymentId
    }
    this.allTrainingListService.checkPaymentConfirmation(data).subscribe(
      (res) => {
        if(res.status == 'succsess') {
          this.showSuccess(res.ConfirmedMessage);
          // Swal.fire({
          //   title: 'Success',
          //   text: res.ConfirmedMessage,
          //   icon: 'success',
          //   confirmButtonText: 'Ok',
          // });
        } else {
          this.showError(res.ConfirmedMessage)
        }
        this.router.navigate([res.payment_redirect_url]);
      },
      (err) => {
        this.dataLoading = false;
      }
    );

  }

  showSuccess(msg) {
    this.toastr.success(msg);
  }
  showError(msg) {
    this.toastr.error(msg);
  }

}
