<section class="create payment">
  <div class="create_title">{{'traningDetails.Upload File'|translate}}</div>
  <div cdkFocusInitial class="close-dialog" mat-dialog-close>
    <svg height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
      <path d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
        data-name="Path 7160" fill="#0c2e56" id="Path_7160" transform="translate(-5 -5)" />
    </svg>
  </div>



  <mat-dialog-content>
    <div class="create_content">
      <div class="row">
        <form (ngSubmit)="onSubmit()" [formGroup]="UploadForm" class="w-100">
          <div class="col-12 py-3 border-bottom">
            <h6 class="fields-title mb-3">{{"training.Payment Proof"|translate}} : </h6>
            <app-file-manager (deletedFilesList)="deletedFiles = $event" (selectedFiles)="attachedFiles = $event"
              [files]="files" [isSubmited]="isSubmitted" [managerIndex]="'wordFile'" [maxFiles]="1"
              [isRequired]="true"></app-file-manager>
          </div>
          <div class="col-12 py-3 border-bottom">
            <h6 class="fields-title mb-3">{{"training.Payment Type"|translate}} : </h6>

            <mat-radio-group aria-label="Select an option" formControlName="payment_type">
              <mat-radio-button [value]="1">{{"training.individual Payment"|translate}}</mat-radio-button>
              <mat-radio-button class="mx-3" [value]="2">{{"training.Multi Payments"|translate}}</mat-radio-button>
              <mat-error *ngIf="UploadForm.controls['payment_type'].hasError('required')">
                {{'userProfile.Field is Required' | translate}}
              </mat-error>
            </mat-radio-group>

            <mat-form-field class="example-full-width mt-3" [hidden]="UploadForm.value?.payment_type != 2">
              <mat-label>{{"training.Number of Participants"|translate}}</mat-label>
              <input [required]="UploadForm.value?.payment_type === 2" placeholder="1:999" matInput maxlength="3"
                formControlName="number_of_participants">
              <mat-error *ngIf="UploadForm.controls['number_of_participants'].hasError('required')">
                {{'userProfile.Field is Required' | translate}}
              </mat-error>
              <mat-error *ngIf="UploadForm.controls['number_of_participants'].hasError('pattern')">
                {{'forms.Invalid number' | translate}}
              </mat-error>
              <!-- <mat-error *ngIf="invalidNumber && UploadForm.value.number_of_participants.toString().length">
                Invalid number
              </mat-error> -->
            </mat-form-field>
          </div>
          <div class="col-12 pt-3 ">
            <h6 class="fields-title mb-3">{{"training.Account Holder Name/Number"|translate}} : </h6>
            <mat-form-field class="example-full-width">
              <mat-label>{{"training.Account Holder Name/Number"|translate}}</mat-label>
              <input matInput formControlName="account_holder_name">
              <mat-error *ngIf="UploadForm.controls['account_holder_name'].hasError('required')">
                {{'userProfile.Field is Required' | translate}}
              </mat-error>
              <mat-error *ngIf="UploadForm.controls['account_holder_name'].hasError('min')">
                {{'userProfile.Field is Required' | translate}}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 mt-3">
            <div class="btn-container">
              <button type="submit" [class.loading]="loading" class="btn-create w-100" color="primary"
                mat-raised-button>
                <span>{{"traningDetails.Submit"|translate}}</span>
              </button>
            </div>
          </div>

        </form>






      </div>
    </div>
  </mat-dialog-content>
</section>