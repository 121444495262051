import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/features/auth/services/auth.service';
import { ProfileService } from 'src/app/features/user-management/service/profile.service';

@Component({
  selector: 'app-edit-info-payment',
  templateUrl: './edit-info-payment.component.html',
})
export class EditInfoPaymentComponent implements OnInit {
  loading = false;
  editPerofileInfoForm: FormGroup;
  countryCodes: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditInfoPaymentComponent>,
    private profileService: ProfileService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.editPersonalInfoFormInit();
    this.onGetCountryCode();
  }

  editPersonalInfoFormInit() {
    this.editPerofileInfoForm = this.formBuilder.group({
      user_city: [
        '',
        [Validators.required, Validators.pattern('^[a-zA-Z-_ ]+$')],
      ],
      user_state: [
        '',
        [Validators.required, Validators.pattern('^[a-zA-Z-_ ]+$')],
      ],
      user_street: [
        '',
        [Validators.required, Validators.pattern('^[a-zA-Z-_ ]+$')],
      ],
      user_postcode: ['', [Validators.required]],
      country_token: ['', [Validators.required]],
    });
  }

  onPersonalInfoSubmit() {
    console.log(this.editPerofileInfoForm);
    if (this.editPerofileInfoForm.valid) {
      this.loading = true;
      const model = {
        user_city: this.editPerofileInfoForm.controls.user_city.value,
        user_state: this.editPerofileInfoForm.controls.user_state.value,
        user_street: this.editPerofileInfoForm.controls.user_street.value,
        user_postcode: this.editPerofileInfoForm.controls.user_postcode.value,
        country_token: this.editPerofileInfoForm.controls.country_token.value,
      };
      this.submit(model);
    }
  }

  submit(model) {
    this.profileService.updateMybillingAddress(model).subscribe(
      (res) => {
      this.toastr.success(res.message);
      this.loading = false;
      this.dialogRef.close(model);
    },
    (err) => {
      this.loading = false;
      this.toastr.warning(err.message);

    });
  }

  onGetCountryCode() {
    this.authService.getCountryCode().subscribe((res) => {
      this.countryCodes = res.data;
    });
  }

  hasError(controlName: string, errorName: string) {
    return this.editPerofileInfoForm.controls[controlName].hasError(errorName);
  }
  regionCompare(o1, o2) {
    return o1 == o2;
  }
}
