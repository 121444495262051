import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AllTrainingListService } from 'src/app/features/training/services/all-training-list.service';

@Component({
  templateUrl: './upload-proof.component.html',
  styles: [],
})
export class UploadProofComponent implements OnInit {
  loading = false;
  files = [];
  deletedFiles: any[] = [];
  attachedFiles: any[] = [];
  isSubmitted = false;
  UploadForm: FormGroup = new FormGroup({
    payment_type: new FormControl(1, [Validators.required]),
    number_of_participants: new FormControl(1, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
    account_holder_name: new FormControl(null, [Validators.required])
  })
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UploadProofComponent>,
    private allTrainingListService: AllTrainingListService,
    private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
    console.log(this.data);
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.UploadForm.valid && this.files.length) {
      this.loading = true;
      const model = {
        ...this.UploadForm.value,
        course_token: this.data.courseToken,
        enrollment_token: this.data.enrollment_token,
        payment_option: 1,
        attached_file: this.files,
      };
      if (this.data.update_payment) { // when traininee update payment before TM || FM accept or after reject payment
        this.allTrainingListService.traineeUpdatePayment(model).subscribe(
          (res) => {
            this.dialogRef.close(true);
            this.loading = false;
          },
          (err) => {
            this.toastr.error(err.message);
            this.loading = false;
          }
        );
      } 
      else if(this.data.TM_FM_Pay){ //when TM_FM_Pay fees for trainee front training detailes => tap "Payments"
        // debugger
        this.allTrainingListService.TM_FM_Payment(model).subscribe(
          (res) => {
            this.dialogRef.close(true);
            this.loading = false;
          },
          (err) => {
            this.toastr.error(err.message);
            this.loading = false;
          }
        );
      }
      // when Trainee Pay For first Time
      else {
        this.allTrainingListService.traineePayment(model).subscribe(
          (res) => {
            this.dialogRef.close(true);
            this.loading = false;
          },
          (err) => {
            this.toastr.error(err.message);
            this.loading = false;
          }
        );
      }
    }



  }
}
