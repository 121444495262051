import { AuthService } from './../../../features/auth/services/auth.service';
import {
  Component,
  EventEmitter,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { NotificationsService } from 'src/app/features/notifications/services/notifications.service';
import { NotificationsSearchModel } from 'src/app/core/models/notification/NotificationsSearchModel';
import { interval, Subscription } from 'rxjs';

import { TasksList } from 'src/app/core/models/notification/tasksList';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  language = localStorage.getItem('language') || 'en';
  isArabic = false;

  @Output() sidebarTrigger = new EventEmitter();
  @Output() sidebarLargScreen = new EventEmitter();
  @Output() closeSidebar = new EventEmitter();
  notificationNumber: number;
  currentUser;
  userMenu = false;
  shouldShow = false;
  toggle = false;
  userToken;
  pageNumber = 0;

  notifLoading = false;
  tasksLoading = false;
  tasksPageCount: number;

  pageCount: number;
  notificationMenu = false;
  tasksMenu = false;
  notificationsResult: any = {
    unSeenNotificationsCount: 0,
    totalCount: 0,
  };
  profileDataSubscriber: Subscription;
  notificationsList: any[] = [];
  allNotificationIsSeen = true;

  tasksList: any[] = [];

  heartBeat: Subscription;
  NotificationsObject: NotificationsSearchModel =
    new NotificationsSearchModel();

  tasksListModel: TasksList = new TasksList();
  allTasksIsSeen = true;
  newMessage: string;
  showLocalizationIcon = false;

  constructor(
    public zone: NgZone,
    private router: Router,
    public translate: TranslateService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private notificationService: NotificationsService,
    private route: ActivatedRoute
  ) {
    this.currentUser = this.authService.currentUserValue;
  }

  ngOnInit() {
    this.showLocalizationIcon = JSON.parse(
      localStorage.getItem('authUser')
    ).is_localize;
    this.userToken = JSON.parse(localStorage.getItem('userToken'));
    this.profileDataSubscriber = this.authService.userProfileChanged.subscribe(
      (data) => {
        if (data) {
          this.currentUser.fullName = `${data.user_first_name} ${data.user_father_name} ${data.usre_grandfather_name} ${data.user_family_name}`;
          this.currentUser.profile_img = data.logo;
        }
      }
    );
    this.onInitNotification();
    this.onInitNotificationData();
    this.webSocketHeartBeat();
    this.onGetTasksList();
  }

  onInitNotification() {
    this.notificationService.getNewMessage().subscribe((message: any) => {
      // console.log(message);
      if (message.data) {
        this.allNotificationIsSeen = false;
        if (message.status == 1) {
          this.updateNotification();
          this.notificationsList.unshift(message.data);
        } else if (message.status == 2) {
          this.updateTask();
          this.tasksList.unshift(message.data);
        }
      }
    });
  }

  webSocketHeartBeat() {
    const timeToHeartBeatWebSocket = interval(300000);
    this.heartBeat = timeToHeartBeatWebSocket.subscribe((val) => {
      this.notificationService.getNewMessage().subscribe((message: any) => {
        // console.log('Pinged');
      });
    });
  }

  onInitNotificationData() {
    this.notifLoading = true;
    this.notificationService
      .initNotificationData(this.NotificationsObject)
      .subscribe(
        (res) => {
          this.notificationsList = this.notificationsList.concat(res.data);
          this.pageCount = Math.ceil(
            res.count_items / this.NotificationsObject.per_page
          );
          this.notifLoading = false;
          // console.log(this.notificationsList);
          this.onNotificationCheckIsSeen(this.notificationsList);
        },
        (err) => {
          this.notifLoading = false;
          console.error(err);
        }
      );
  }

  updateNotification() {
    this.openSnackBar('You have a new notification');
  }
  updateTask() {
    this.openSnackBar('You have a new task');
  }

  openSnackBar(msg) {
    this.snackBar.open(msg, '✖', {
      duration: 10000,
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
    });
  }

  changeLanguage() {
    if (this.language === 'en') {
      localStorage.setItem('language', 'ar');
    } else {
      localStorage.setItem('language', 'en');
    }
    window.location.reload();
  }

  logout() {
    this.authService.logout();
    localStorage.setItem('language', 'en');
    window.location.reload();
    this.router.navigate(['/login']);
  }

  toggleSearchBar() {
    this.shouldShow = !this.shouldShow;
  }

  initName(name) {
    if (!name) {
      return '';
    }
    let initials = name.match(/\b\w/g) || [];
    initials = (
      (initials.shift() || '') + (initials.pop() || '')
    ).toUpperCase();
    return initials;
  }

  openNotificationPanel() {
    this.notificationMenu = !this.notificationMenu;
    this.userMenu = false;
    this.tasksMenu = false;
    this.updateNotificationsSeenStatus();
  }

  openTasksPanel() {
    this.tasksMenu = !this.tasksMenu;
    this.userMenu = false;
    this.notificationMenu = false;
  }

  onGetTasksList() {
    //debugger
    this.tasksLoading = true;
    this.notificationService.getTasksList(this.tasksListModel).subscribe(
      (res) => {
        this.tasksList = this.tasksList.concat(res.data);
        this.tasksPageCount = Math.ceil(
          res.count_items / this.tasksListModel.per_page
        );
        this.tasksLoading = false;
        this.onTasksCheckIsSeen(this.tasksList);
      },
      (err) => {
        this.tasksLoading = false;
        console.error(err);
      }
    );
  }

  getTasksNextPage() {
    //debugger
    if (this.tasksListModel.page_number < this.tasksPageCount - 1) {
      this.tasksListModel.page_number += 1;
      this.onGetTasksList();
    }
  }

  getNextPage() {
    if (this.NotificationsObject.page_number < this.pageCount - 1) {
      this.NotificationsObject.page_number += 1;
      this.onInitNotificationData();
    }
  }

  updateNotificationsSeenStatus() {
    this.notificationService.markAllNorificationsIsSeen().subscribe((res) => {
      this.allNotificationIsSeen = true;
    });
  }

  @HostListener('window:keydown', ['$event']) handleKeyboardEvent(
    event: KeyboardEvent
  ) {
    if (event.key === 'Escape') {
      this.closeOpenedDrop();
    }
  }

  @HostListener('document:click', ['$event']) onDocumentClick(
    event: MouseEvent
  ) {
    if (event.target === document.getElementById('popOverlay')) {
      this.closeOpenedDrop();
    }
  }

  closeOpenedDrop() {
    this.userMenu = false;
    this.shouldShow = false;
    this.notificationMenu = false;
    this.tasksMenu = false;
  }

  // onClickMyProfile() {
  //   const
  //   this.router.navigate([`/UserManagement/CABSUsers`]);
  // }

  onTasksCheckIsSeen(arr: any[]) {
    arr.forEach((element) => {
      if (element.status == false) {
        this.allTasksIsSeen = false;
      }
    });
  }

  onNotificationCheckIsSeen(arr: any[]) {
    arr.forEach((element) => {
      if (element.is_seen == false) {
        this.allNotificationIsSeen = false;
      }
    });
  }

  navigateTask(task) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([
        '/admin/' + task.task_link,
        task.reference_token,
      ])
    );
    window.open(url, '_blank');
    // this.router.navigate(['/admin/' + task.task_link, task.reference_token]);
  }

  open() {
    if (!this.toggle) {
      this.toggle = true;
      this.sidebarLargScreen.emit(this.toggle);
    } else {
      this.toggle = false;
      this.sidebarLargScreen.emit(this.toggle);
    }
  }

  ngOnDestroy() {
    this.notificationService.disconnect();
  }
}
