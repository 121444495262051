<mat-form-field >
  <mat-label>{{labelName}}</mat-label>
  <input matInput [matDatepicker]="dp" [formControl]="date" [required]="isRequired" readonly>
  <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
  <mat-datepicker #dp
    startView="multi-year"
    (monthSelected)="setMonthAndYear($event, dp)"
    panelClass="example-month-picker">
  </mat-datepicker>
  <mat-error *ngIf="date.errors?.required"> Is Required </mat-error>
</mat-form-field>
