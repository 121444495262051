import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/features/auth/services/auth.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
})
export class AdminLayoutComponent implements OnInit {
  toggleSide = false;
  sidebarLargScreen = false;
  menuLoading = true;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.authService.refreshLang.subscribe((data) => {
      console.log(data);

      if (data) {
        window.location.reload();
      }
    });
    this.authService.refreshLang.next(null);
  }
}
