<div class="basic">
  <header>
    <div class="container-fluid">
      <div class="d-flex justify-content-between">
        <a class="logo">
          <img alt="" src="./assets/images/GAC-logo-hor-white.svg"/>
        </a>
        <a (click)="changeLanguage()" class="lang-btn">
            <img src="./assets/images/icons/language-icon.svg" alt="">
            <span>{{ 'general.language' | translate }}</span>
        </a>
        <button
          [routerLink]="'login'"
          class="mr-4 login-btn d-none"
          mat-stroked-button
        >
          Login
        </button>
        <!-- <a (click)="changeLanguage()">
          <img alt="" src="./assets/images/icons/language-icon.svg"/>
          <span>
            {{ 'general.language' | translate }}
          </span></a
        > -->
      </div>
    </div>
  </header>
  <router-outlet></router-outlet>
  <footer class="footer-bottom">
    <div class="container">
      <div>
        <div class="copyRight">
          {{ 'footer.copy-right' | translate }}
        </div>
      </div>
    </div>
  </footer>
</div>
