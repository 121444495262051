<section class="create payment">
  <div class="create_title">Online Payment</div>
  <div cdkFocusInitial class="close-dialog" (click)="closePayment()">
    <svg
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
        data-name="Path 7160"
        fill="#0c2e56"
        id="Path_7160"
        transform="translate(-5 -5)"
      />
    </svg>
  </div>
  <mat-dialog-content>
    <div class="create_content">
      <ng-container *ngIf="!paymentProvider">
        <h5>choose payment method below</h5>
        <div class="e-payment-cards-container">
          <div class="row">
            <div class="col-lg-4">
              <div class="e-payment-card" (click)="changeMethod(1)" [class.active]="paymentProvider == 1">
                <img src="./assets/images/visa-card.png" alt="">
                <div class="e-payment-title">PAY WITH VISA, MASTER,AMEX, STC_PAY</div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="e-payment-card" (click)="changeMethod(2)" [class.active]="paymentProvider == 2">
                <!-- applepay_logo.png -->
                <img src="./assets/images/apple-pay.webp" alt="">
                <div class="e-payment-title">PAY WITH APPLE PAY</div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="e-payment-card" (click)="changeMethod(3)" [class.active]="paymentProvider == 3">
                <img src="./assets/images/mada_logo.png" alt="">
                <div class="e-payment-title">PAY WITH MADA</div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>


      <div *ngIf="dataLoading" class="dataLoading">
        <mat-spinner class="m-auto" diameter="50"></mat-spinner>
      </div>

      <form *ngIf="!dataLoading && paymentProvider == 1" id="eFinanceForm" [action]="redirectLink" class="paymentWidgets" data-brands="VISA MASTER"></form>
      <form *ngIf="!dataLoading && paymentProvider == 2" id="eFinanceForm" [action]="redirectLink" class="paymentWidgets" data-brands="VISA MASTER MADA"></form>
      <form *ngIf="!dataLoading && paymentProvider == 3" id="eFinanceForm" [action]="redirectLink" class="paymentWidgets" data-brands="MADA"></form>

    </div>
  </mat-dialog-content>
</section>




