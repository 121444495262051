import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GroupTrainingRequestService } from 'src/app/features/group-training-requests/services/group-training-request.service';
import { whiteSpaceValidator } from '../../Validators/no-white-space';

export interface DialogData {
  requestedFromExsiestTraining: any;
  trainingToken: any;
  trainingName: any;
  requestToken: any;
  Attendees: [];
  editMode: any;
  feesForrGroup: any;
  currency: any;
}

@Component({
  selector: 'app-request-group-training',
  templateUrl: './request-group-training.component.html',
  styles: [],
})
export class RequestGroupTrainingComponent implements OnInit {
  Loading = false;
  formGroup: FormGroup;
  ActiveCollaped = [true];

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public Ddata: DialogData,
    public dialogRef: MatDialogRef<RequestGroupTrainingComponent>,
    private groupTrainingRequestService: GroupTrainingRequestService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  // Attendees array
  get Attendees() {
    return this.formGroup.controls.Attendees as FormArray;
  }

  ngOnInit(): void {
    this.buildForm();
    if (this.Ddata.requestedFromExsiestTraining) {
      this.formGroup.controls.training_name.setValue(this.Ddata.trainingName);
      this.formGroup.controls.training_name.disable();
    }
    if (this.Ddata.editMode) {
      this.bindData();
    }
  }

  bindData() {
    this.formGroup.controls.training_name.setValue(this.Ddata.trainingName);
    this.Ddata.Attendees.forEach((element) => {
      this.addAttendees(element);
    });
    this.removeAttendees(0);
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      training_name: [null,
        [
          Validators.required,
          Validators.maxLength(200)

        ],
      ],
      Attendees: this.formBuilder.array([
        this.formBuilder.group({
          first_name: [
            '',
            [
              Validators.required,
              whiteSpaceValidator,
              Validators.pattern('^[a-zA-Z ]+$'),
            ],
          ],
          father_name: [
            '',
            [
              Validators.pattern('^[a-zA-Z ]+$'),
            ],
          ],
          grandfather_name: [
            '',
            [
              Validators.pattern('^[a-zA-Z ]+$'),
            ],
          ],
          family_name: [
            '',
            [
              Validators.required,
              whiteSpaceValidator,
              Validators.pattern('^[a-zA-Z ]+$'),
            ],
          ],
          mobile: [
            null,
            [
              Validators.required,
              Validators.minLength(10),
              Validators.pattern('^[0-9()/ -]*$'),
            ],
          ],
          email: [
            null,
            [
              Validators.pattern(
                '[a-zA-Z0-9._-]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}'
              ),
              Validators.required,
            ],
          ],
        }),
      ]),
    });
  }

  public addAttendees($el = null) {
    this.Attendees.push(
      this.formBuilder.group({
        first_name: [$el ? $el.first_name : '', Validators.required],
        father_name: [$el ? $el.father_name : '', Validators.required],
        grandfather_name: [
          $el ? $el.grandfather_name : '',
          Validators.required,
        ],
        family_name: [$el ? $el.family_name : '', Validators.required],
        mobile: [
          $el ? $el.mobile : null,
          [Validators.required, Validators.minLength(10)],
        ],
        email: [
          $el ? $el.email : null,
          [
            Validators.pattern(
              '[a-zA-Z0-9._-]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}'
            ),
            Validators.required,
          ],
        ],
      })
    );
    if ($el) {
      this.ActiveCollaped.push(false);
    } else {
      this.ActiveCollaped.push(true);
    }
  }

  public removeAttendees($index: number) {
    this.Attendees.removeAt($index);
    if ($index == 0 && this.Attendees.length == 0) {
      this.addAttendees();
    }
    this.ActiveCollaped.splice($index, 1);
  }

  // requestTraining(){
  //   console.log(this.formGroup);

  // }
  requestTraining() {
    if (this.formGroup.valid) {
      this.Loading = true;
      let obj;
      obj = {
        Attendees: this.Attendees.value,
      } as any;
      // type ::  0 => new course , 1 => exsiest
      if (this.Ddata.requestedFromExsiestTraining) {
        obj.type = 1;
        obj.tc_token = this.Ddata.trainingToken;
      } else {
        obj.type = 0;
        obj.training_name = this.formGroup.controls.training_name.value;
      }

      if (this.Ddata.editMode) {
        obj.request_token = this.Ddata.requestToken;
      }

      this.groupTrainingRequestService.requestGroupTraining(obj).subscribe(
        (data) => {
          this.Loading = false;
          // Swal.fire({
          //   title: 'success',
          //   text: this.Ddata.editMode ? 'Added Successfully' : 'Updated Successfully',
          //   icon: 'success',
          //   confirmButtonText: 'ok',
          // });
          this.showSuccess(data.message);
          if (this.Ddata.requestedFromExsiestTraining) {
            this.router.navigate([
              'admin/group-training-requests/details/',
              data.token,
            ]);
          }
          // this.groupTrainingRequestService.refreshDetails.next(true);
          this.dialogRef.close(true);
        },
        (err) => {
          this.Loading = false;
          err.message.forEach((element) => {
            this.showError(element.message);
          });
        }
      );
    }
  }

  hasError(formGroup, controlName: string, errorName: string) {
    return formGroup.controls[controlName].hasError(errorName);
  }

  hasArrayError(formGroup, controlName: string, errorName: string) {
    return formGroup.get(controlName).hasError(errorName);
  }

  showError(msg) {
    this.toastr.error(msg);
  }

  showSuccess(msg) {
    this.toastr.success(msg);
  }
}
