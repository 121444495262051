import { UnixDate } from './pipes/unixDate.pipe';
import { AccessControlDirective } from './directive/access-control.directive';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { ShortNamePipe } from './pipes/shortName.pipe';
import { ResendmodalComponent } from './components/resendmodal/resendmodal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings, } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MaterialModule } from './modules/material.module';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { FileManagerComponent } from './components/file-manager/file-manager.component';
import { FilesViewerComponent } from './components/files-viewer/files-viewer.component';
import { DndDirective } from './directive/dnd.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { LightboxModule } from 'ngx-lightbox';

import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { RomanNumber } from './pipes/romanian.pipe';
import { CustomYearPickerComponent } from './components/custom-year-picker/custom-year-picker.component';
import { PaymentModalComponent } from './components/payment-modal/payment-modal.component';
import { OnlynumberDirective } from './directive/only-num.directive';
import { UploadProofComponent } from './components/upload-proof/upload-proof.component';
import { RequestGroupTrainingComponent } from './components/request-group-training/request-group-training.component';
import { PaymentRequestModalComponent } from './components/payment-request-modal/payment-request-modal.component';
import { ConfirmationRemoveRoleComponent } from './components/confirmation-remove-role/confirmation-remove-role.component';
import { FilesViewerBtnComponent } from './components/files-viewer-btn/files-viewer-btn.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PhotoManagerComponent } from './components/photo-manager/photo-manager.component';
import { SanitizeHtmlPipePipe } from './pipes/sanitize-html-pipe.pipe';
import { TreeSelectionComponent } from './components/tree-selection/tree-selection.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { NoPermissionComponent } from './components/no-permission/no-permission.component';
import { CabListComponent } from './components/cab-list/cab-list.component';
import { MultiTreeSelectionComponent } from './components/multi-tree-selection/multi-tree-selection.component';
import { EfinanceFormComponent } from './components/efinance-form/efinance-form.component';
import { PaymentConfirmationPageComponent } from './components/payment-confirmation-page/payment-confirmation-page.component';
import { MonthYearPickerComponent } from './components/month-year-picker/month-year-picker.component';
import { EditInfoPaymentComponent } from './components/edit-info-payment/edit-info-payment.component';
import { SelectTranslatedTextDirective } from './directive/select-translated-text.directive';

@NgModule({
  declarations: [
    ResendmodalComponent,
    ShortNamePipe,
    UnixDate,
    ConfirmationModalComponent,
    FileManagerComponent,
    FilesViewerComponent,
    DndDirective,
    AccessControlDirective,
    RomanNumber,
    CustomYearPickerComponent,
    PaymentModalComponent,
    OnlynumberDirective,
    UploadProofComponent,
    RequestGroupTrainingComponent,
    PaymentRequestModalComponent,
    ConfirmationRemoveRoleComponent,
    FilesViewerBtnComponent,
    PhotoManagerComponent,
    SanitizeHtmlPipePipe,
    TreeSelectionComponent,
    PageNotFoundComponent,
    NoPermissionComponent,
    CabListComponent,
    MultiTreeSelectionComponent,
    EfinanceFormComponent,
    PaymentConfirmationPageComponent,
    MonthYearPickerComponent,
    EditInfoPaymentComponent,
    SelectTranslatedTextDirective
  ],
  imports: [
    CommonModule,

    MaterialModule,
    RecaptchaModule,
    FormsModule,
    ReactiveFormsModule,
    // LightboxModule,
    // TranslateModule.forRoot({}),
    TranslateModule.forChild({}),
    NgxIntlTelInputModule,
    NgbModule,
    NgxMaterialTimepickerModule,
    InfiniteScrollModule,
  ],

  exports: [
    TranslateModule,
    MaterialModule,
    RecaptchaModule,
    FormsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    // LightboxModule,
    ResendmodalComponent,
    ShortNamePipe,
    UnixDate,
    ConfirmationModalComponent,
    FileManagerComponent,
    FilesViewerComponent,
    DndDirective,
    AccessControlDirective,
    NgbModule,
    NgxMaterialTimepickerModule,
    RomanNumber,
    CustomYearPickerComponent,
    PaymentModalComponent,
    OnlynumberDirective,
    RequestGroupTrainingComponent,
    ConfirmationRemoveRoleComponent,
    FilesViewerBtnComponent,
    InfiniteScrollModule,
    PhotoManagerComponent,
    SanitizeHtmlPipePipe,
    TreeSelectionComponent,
    CabListComponent,
    MultiTreeSelectionComponent,
    MonthYearPickerComponent,
    SelectTranslatedTextDirective
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,

      useValue: {
        siteKey: environment.googleSiteKey,
        useValue: localStorage.getItem('language'),
      } as RecaptchaSettings,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
  ],
})
export class SharedModule {
}
