<footer class="footer">
  <svg
    height="13"
    viewBox="0 0 13 13"
    width="13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M-7.575-5.307a1.852,1.852,0,0,1,.2-.58,1.143,1.143,0,0,1,.366-.4,1.054,1.054,0,0,1,.6-.153.944.944,0,0,1,.427.092,1.222,1.222,0,0,1,.32.229,1.151,1.151,0,0,1,.229.336.972.972,0,0,1,.092.412h1.16a2.061,2.061,0,0,0-.168-.839,2,2,0,0,0-.458-.656,2.032,2.032,0,0,0-.7-.427,2.573,2.573,0,0,0-.9-.153,2.532,2.532,0,0,0-1.114.229,2.214,2.214,0,0,0-.778.61,2.614,2.614,0,0,0-.458.885,3.61,3.61,0,0,0-.153,1.053v.183a4.156,4.156,0,0,0,.137,1.068,2.16,2.16,0,0,0,.458.885,2.291,2.291,0,0,0,.793.58,2.686,2.686,0,0,0,1.114.214,2.6,2.6,0,0,0,.854-.137,2.167,2.167,0,0,0,.7-.4,1.831,1.831,0,0,0,.473-.61,2.089,2.089,0,0,0,.2-.748h-1.16a.813.813,0,0,1-.092.366.9.9,0,0,1-.244.305,1.081,1.081,0,0,1-.336.183,1.247,1.247,0,0,1-.4.061,1.145,1.145,0,0,1-.6-.137.989.989,0,0,1-.366-.4,2.052,2.052,0,0,1-.2-.58,3.5,3.5,0,0,1-.061-.656v-.183A3.262,3.262,0,0,1-7.575-5.307Zm1.251-5.768a6.461,6.461,0,0,0-4.608,1.892,6.461,6.461,0,0,0-1.892,4.608A6.461,6.461,0,0,0-10.932.034,6.461,6.461,0,0,0-6.324,1.926,6.461,6.461,0,0,0-1.716.034,6.461,6.461,0,0,0,.176-4.574,6.461,6.461,0,0,0-1.716-9.182,6.461,6.461,0,0,0-6.324-11.074Zm0,11.718A5.209,5.209,0,0,1-10-.9a5.209,5.209,0,0,1-1.541-3.677A5.209,5.209,0,0,1-10-8.251,5.209,5.209,0,0,1-6.324-9.793,5.209,5.209,0,0,1-2.647-8.251,5.209,5.209,0,0,1-1.106-4.574,5.209,5.209,0,0,1-2.647-.9,5.209,5.209,0,0,1-6.324.644Z"
      fill="#0c2e56"
      id="copyright-icon"
      transform="translate(12.824 11.074)"
    />
  </svg>
  {{ 'training.Copyright 2022' | translate}} <span class="semi-bold"> {{ 'training.GAC' | translate}} </span> , {{ 'training.All Rights Reserved' | translate}}
</footer>
