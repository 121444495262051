<section class="create">
  <div class="create_title"> {{ 'TrainingDetails.Complete your Personal Info' | translate }}</div>
  <div cdkFocusInitial class="close-dialog" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </div>
  <mat-dialog-content>
    <div class="create_content">
      <form (ngSubmit)="onPersonalInfoSubmit()" [formGroup]="editPerofileInfoForm">
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field appearance="outline" class="form-group">
              <mat-label>{{ 'userProfile.Country' | translate }}</mat-label>
              <mat-select [compareWith]="regionCompare" class="profile-inputs_c-grey-6" formControlName="country_token">
                <mat-option *ngFor="let item of countryCodes" [value]="item.country_token">{{ item.countryName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="hasError('country_token', 'required')">
                {{ 'userProfile.Field is Required' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-12">
            <mat-form-field appearance="outline" class="form-group">
              <mat-label>{{ 'userProfile.City' | translate }}</mat-label>
              <input class="profile-inputs_c-grey-6" formControlName="user_city" matInput maxlength="80" />
              <mat-error *ngIf="hasError('user_city', 'required')">
                {{ 'userProfile.Field is Required' | translate }}
              </mat-error>
              <mat-error *ngIf="hasError('user_city', 'pattern')">
                {{ 'userProfile.Field accept only English Letters and Spaces' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-12">
            <mat-form-field appearance="outline" class="form-group">
              <mat-label>{{ 'userProfile.State' | translate }}</mat-label>
              <input class="profile-inputs_c-grey-6" formControlName="user_state" matInput maxlength="50" />
              <mat-error *ngIf="hasError('user_state', 'required')">
                {{ 'userProfile.Field is Required' | translate }}
              </mat-error>
              <mat-error *ngIf="hasError('user_state', 'pattern')">
                {{ 'userProfile.Field accept only English Letters and Spaces' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-12">
            <mat-form-field appearance="outline" class="form-group">
              <mat-label>{{ 'userProfile.Street' | translate }}</mat-label>
              <input class="profile-inputs_c-grey-6" formControlName="user_street" matInput maxlength="100" />
              <mat-error *ngIf="hasError('user_street', 'required')">
                {{ 'userProfile.Field is Required' | translate }}
              </mat-error>
              <mat-error *ngIf="hasError('user_street', 'pattern')">
                {{ 'userProfile.Field accept only English Letters and Spaces' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-12">
            <mat-form-field appearance="outline" class="form-group">
              <mat-label>{{ 'userProfile.postcode' | translate }}</mat-label>
              <input class="profile-inputs_c-grey-6" formControlName="user_postcode" appOnlynumber matInput
                maxlength="16" type="number" />
              <mat-error *ngIf="hasError('user_postcode', 'required')">
                {{ 'userProfile.Field is Required' | translate }}
              </mat-error>
              <!-- <mat-error *ngIf="hasError('user_postcode', 'pattern')">
              Field accept only English Letters and Spaces
            </mat-error> -->
            </mat-form-field>
          </div>
          <div class="col-sm-12 profile-actions">
            <div class="row">
              <div class="col-md-12">
                <button [class.loading]="loading" [disabled]="loading" class="mat_red w-100" mat-raised-button>
                  <span>{{ 'userProfile.Save Changes' | translate }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-dialog-content>
</section>
