import { TranslateService } from '@ngx-translate/core';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { UploadProofComponent } from '../upload-proof/upload-proof.component';
import Swal from 'sweetalert2';
import { AccreditationFinancialService } from 'src/app/features/cabs/modules/accreditation-financial/services/accreditation-financial.service';
import { AllTrainingListService } from 'src/app/features/training/services/all-training-list.service';
import { EfinanceFormComponent } from '../efinance-form/efinance-form.component';
import { saveAs } from 'file-saver';
import { EditInfoPaymentComponent } from '../edit-info-payment/edit-info-payment.component';

@Component({
  templateUrl: './payment-modal.component.html',
  styles: [],
})
export class PaymentModalComponent implements OnInit {
  loading = false;

  HasedRequestObject: any = '';
  RandomSecret: any = '';
  RequestObject: any = '';
  SenderID: any = '';
  payment_gateway_url: any = '';
  dataLoading = false;
  showAlertMessage = false;
  paymentOptions: any = [];

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PaymentModalComponent>,
    private toastr: ToastrService,
    private accreditationFinancialService: AccreditationFinancialService,
    private allTrainingListService: AllTrainingListService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    console.log(this.data);
    if (this.data.TM_FM_Pay) {
      this.getPaymentOptions_notDependentOnCurrency();
    } else {
      this.getPaymentOptions();
    }

  }

  getPaymentOptions() {
    console.log(this.data);

    this.dataLoading = true;
    const model:any = {
      currency_token: this.data.data.currency_token,
      course_token: this.data.courseToken
    };
    // if (this.data.show_mendatory_payment_popup) {
    //   model.course_token = this.data.courseToken
    // }
    this.accreditationFinancialService.getPaymentOptions(model).subscribe(
      (res) => {
        // debugger
        this.dataLoading = false;
        this.paymentOptions = res.data;
        this.showAlertMessage = res.show_alert_message
        console.log(res);
      },
      (err) => {
        this.showError(err.message);
        this.dataLoading = false;
      }
    );
  }

  getPaymentOptions_notDependentOnCurrency() {
    // debugger
    this.dataLoading = true;
    this.accreditationFinancialService.getPaymentOptions_notDependentOnCurrency().subscribe(
      (res) => {
        this.dataLoading = false;
        // Remove the online payment option from list of options
        this.paymentOptions.push({ id: 4, option_name: "Direct payment confirmation", option_token: "HAG43234dAHHrtrBNt8gu9CjWp1vzPkK434xvSJ" }) //new option don't returned from back-end
        res.data.forEach(element => {
          if (element.option_token != 'ABtdzdAHHrtrBNt8gu9CjWp1vzPkKcfc8Qje') {
            this.paymentOptions.push(element)
          }
        })
        // this.paymentOptions = res.data;
        console.log(res);
      },
      (err) => {
        this.showError(err.message);
        this.dataLoading = false;
      }
    );
  }


  onUploadProof() {
    // const otherSkillData = {
    //   userToken: this.userToken,
    //   yearsOfExpList: this.yearsOfExpList,
    // };
    const dialogRef = this.dialog.open(UploadProofComponent, {
      width: '450px',
      data: this.data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.showSuccess('Successfully Added');
        Swal.fire({
          title: this.translate.instant('swalMsg.success'),
          text: this.translate.instant('swalMsg.Your request has been successfully registered, and the finance manager will review your payment , Thank you'),
          icon: 'success',
          confirmButtonText: this.translate.instant('swalMsg.ok'),
        });
        this.dialogRef.close(true);
      }
    });
  }

  onlinePay() {
    this.loading = true;
    const obj = {
      payment_token: this.data.courseToken,
      type: 0, // 0 => Training , 1 => cab
    };
    this.accreditationFinancialService.onlinePayment(obj).subscribe(
      (res) => {
        this.loading = false;
        (this.HasedRequestObject = res.HasedRequestObject),
          (this.RandomSecret = res.RandomSecret),
          (this.RequestObject = res.RequestObject),
          (this.SenderID = res.SenderID),
          (this.payment_gateway_url = res.payment_gateway_url);

        setTimeout(() => {
          let form: HTMLFormElement = <HTMLFormElement>(
            document.getElementById('redirectToPay')
          );
          form.submit();
        }, 40);
      },
      (err) => {
        this.loading = false;
        err.message.forEach((element) => {
          this.showError(element.message);
        });
      }
    );
  }

  onOfficePay() {
    Swal.fire({
      title: this.translate.instant('swalMsg.Areyousure'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('swalMsg.yes'),
      cancelButtonText: this.translate.instant('swalMsg.cancel'),
    }).then((result) => {
      if (result.value) {
        this.loading = true;
        const model = {
          course_token: this.data.courseToken,
          payment_option: 2,
          enrollment_token: this.data.enrollment_token
        };
        if (this.data.update_payment) {
          this.allTrainingListService.traineeUpdatePayment(model).subscribe(
            (res) => {
              this.dialogRef.close(true);
              Swal.fire({
                title: this.translate.instant('swalMsg.success'),
                text: this.translate.instant('swalMsg.Your request has been successfully registered, Thank you'),
                icon: 'success',
                confirmButtonText: this.translate.instant('swalMsg.ok'),
              });
              this.loading = false;
            },
            (err) => {
              // console.log(err);
              this.toastr.error(err.message);
              this.loading = false;
            }
          );
        }
        else if (this.data.TM_FM_Pay) {
          //when TM_FM_Pay fees for trainee front training detailes => tap "Payments"
          this.allTrainingListService.TM_FM_Payment(model).subscribe(
            (res) => {
              this.dialogRef.close(true);
              Swal.fire({
                title: this.translate.instant('swalMsg.success'),
                text: this.translate.instant('swalMsg.Your request has been successfully registered, Thank you'),
                icon: 'success',
                confirmButtonText: this.translate.instant('swalMsg.ok'),
              });
              this.loading = false;
            },
            (err) => {
              this.toastr.error(err.message);
              this.loading = false;
            }
          );
        }
        else {
          this.allTrainingListService.traineePayment(model).subscribe(
            (res) => {
              this.dialogRef.close(true);
              Swal.fire({
                title: this.translate.instant('swalMsg.success'),
                text: this.translate.instant('swalMsg.Your request has been successfully registered, Thank you'),
                icon: 'success',
                confirmButtonText: this.translate.instant('swalMsg.ok'),
              });
              this.loading = false;
            },
            (err) => {
              // console.log(err);
              this.toastr.error(err.message);
              this.loading = false;
            }
          );
        }

      }
    });
  }

  showError(msg) {
    this.toastr.error(msg);
  }

  showSuccess(msg) {
    this.toastr.success(msg);
  }

  checkMyBilling() {
    this.accreditationFinancialService
      .checkMyBillingAddress()
      .subscribe((data: any) => {
        if (data.is_completed) {
          this.onEfinance();
        } else {
          this.editInfoPayment()
        }
        console.log(data);
      });
  }
  editInfoPayment() {
    this.dialogRef.close(false);
    const dialogRef = this.dialog.open(EditInfoPaymentComponent, {
      width: '450px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.showSuccess(this.translate.instant('swalMsg.addedsuccessfully'));
        Swal.fire({
          title: this.translate.instant('swalMsg.success'),
          text: this.translate.instant('swalMsg.addedsuccessfully'),
          icon: 'success',
          confirmButtonText: this.translate.instant('swalMsg.ok'),
        });
        this.dialogRef.close(true);
        this.onEfinance()
      }
    });
  }

  onEfinance(id = null) {
    const modle = {
      type: 0, // training
      payment_token: this.data.courseToken,
      paymentProvider: id,
    };
    this.dialogRef.close(false);
    const dialogRef = this.dialog.open(EfinanceFormComponent, {
      width: '800px',
      data: modle,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.onEfinance(result);
      }
    });
  }

  downloadInvoiseDetails(enrollment_token) {
    this.loading = true;
    const model = {
      enrollment_token: enrollment_token,
    };
    this.allTrainingListService
      .downloadTrainingInvoiseAddressAsPdf(model)
      .subscribe((data: Blob) => {
        this.loading = false;
        const blob = new Blob([data]);
        saveAs(blob, `Invoise Details.pdf`);
      });
  }


  DirectlyConfirmPayment(payment_option) {
    Swal.fire({
      title: this.translate.instant('swalMsg.Areyousure'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('swalMsg.yes'),
      cancelButtonText: this.translate.instant('swalMsg.cancel'),
    }).then((result) => {
      if (result.value) {
        this.loading = true;
        const model = {
          // course_token: this.data.courseToken,
          payment_option: payment_option,
          enrollment_token: this.data.enrollment_token
        };
        // debugger
        //when TM_FM_Pay fees for trainee front training detailes => tap "Payments"
        this.allTrainingListService.TM_FM_Payment(model).subscribe(
          (res) => {
            // debugger
            this.dialogRef.close(true);
            Swal.fire({
              title: this.translate.instant('swalMsg.success'),
              text: this.translate.instant('swalMsg.Your request has been successfully registered, Thank you'),
              icon: 'success',
              confirmButtonText: this.translate.instant('swalMsg.ok'),
            });
            this.loading = false;
          },
          (err) => {
            this.toastr.error(err.message);
            this.loading = false;
          }
        );
      }
    });
  }
}
